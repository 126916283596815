import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "../pages/home";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
]);

export default function Nav() {
    return (
        <RouterProvider router={router}/>
    );
}
