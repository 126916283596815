const particles = {
    particles: {
        number: {
            value: 130,
            density: {
                enable: true,
                height: 800
            }
        },
        color: {
            value: "#ffffff"
        },
        shape: {
            options: {
                particles: {
                    stroke: {
                        width: 3,
                        color: "#000000"
                    },
                }
            },
            type: "circle"
        },
        opacity: {
            value: 0.5,
            random: true,
            anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
            }
        },
        size: {
            value: 4,
            random: true,
            anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
            }
        },
        links: {
            enable: true,
            distance: 300,
            color: "#ffffff",
            opacity: 0.2,
            width: 1,
        },
        move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: false,
            straight: false,
            outModes: {
                default: "out",
            },
            bounce: true,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
    fullscreen: true,
    interactivity: {
        detect_on: "window",
        events: {
            onHover: {
                enable: true,
                mode: "grab"
            },
            onClick: {
                enable: true,
                mode: "push"
            },
            resize: true
        },
        modes: {
            grab: {
                distance: 150,
                line_linked: {
                    opacity: 1
                }
            },
            bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3
            },
            repulse: {
                distance: 200,
                duration: 0.4
            },
            push: {
                quantity: 4
            },
            remove: {
                quantity: 2
            }
        }
    },
    detectRetina: true
}

export default particles